.mt-10 {
  margin-top: 3rem;
}

.fs-12 {
  font-size: 0.8rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-18 {
  font-size: 1.1rem;
}

.fs-20 {
  font-size: 1.3rem;
}

.fs-24 {
  font-size: 1.5rem;
}

.fs-28 {
  font-size: 1.75rem;
}

.fs-32 {
  font-size: 2rem;
}
