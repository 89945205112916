@import '../utils/utilities';

$input-padding: 10px;
$label-color: #aaa;
$label-focus-color: #333;
$border-color: #ccc;
$border-radius: 23px;
$transition-duration: 0.2s;
$transition-ease: ease-out;
$background-color: white;

@mixin transition($property, $duration, $ease) {
  transition: $property $duration $ease;
}

.register-page {
  border: 1px solid #ededed;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  min-width: 900px;

  p {
    a {
      color: $primary-dark-color;
      @include transition(all, $transition-duration, $transition-ease);
    }

    a:hover {
      color: $primary-color;
    }
  }

  form {

    .input-container {
      position: relative;
      margin: 10px 0 20px;
      width: calc(50% - 10px);
      float: left;
    
      input {
        width: 100%;
        padding: $input-padding;
        border: 1px solid $color;
        border-radius: $border-radius;
        outline: none;
      }
    
      label {
        position: absolute;
        top: $input-padding;
        left: $input-padding;
        color: $color;
        pointer-events: none;
        @include transition(all, $transition-duration, $transition-ease);
      }
    
      input:focus + label,
      input:not(:placeholder-shown) + label {
        top: -24px;
        left: $input-padding;
        color: $color;
        background: $background-color; // İsteğe bağlı, arka plan rengi
        padding: 0 5px; // İsteğe bağlı, arka plan rengi görünürlüğü
      }
    }

    button {
      background-color: $primary-dark-color;
      border-radius: $border-radius;
      
      border: none;
      padding: 10px;
      @include transition(all, $transition-duration, $transition-ease);
    }

    button:hover {
      background-color: $primary-color;
      border-radius: $border-radius;
    }
  }

  
  .input-container:nth-child(odd) {
    margin-right: 10px;
  }

  .input-container:nth-child(even) {
    margin-left: 10px;
  }
}
