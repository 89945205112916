@import 'utils/utilities';

.link-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  /* background-color: #04AFFB; */

  a {
    font-family: 'Roboto', sans-serif;
    color: $white;
    font-size: 14px;
    text-decoration: none;
    margin: 0 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    
    svg {
      margin-right: 5px;
    }
  }

  a:hover {
    transition: .3s;
    border-color: $secondary-color
  }
}

.header-body {
  background-color: $primary-dark-color;
  border-bottom: 2px solid $secondary-color;

  .logo-content {
    margin: 15px 0;
  }

  .search-content {
    max-width: 600px;
    flex: 1;
    padding: 0 15px;
    
    .input-group {
      border-radius: 50px;
      background-color: $white;
      border: 1px solid $secondary-color;
      
      input {
        padding: 8px 16px;
        background-color: transparent;
        border: none;
        height: 50px;
      }

      button {
        border: none;
        outline: none;
        width: 50px;

        .search-icon {
          color: $secondary-color;
          transition: color 0.3s;
        }

        .search-icon:hover {
          color: $primary-color;
        }

      }
      button:hover {
        background-color: transparent;
      }
    }
  }

}
